import React from "react";
import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

function Type() {
  const { i18n, t } = useTranslation();
  return (
    <Typewriter
      options={{
        strings: [
          "Software Engineer",
          t("freelancer"),
          t("winner_of_prod"),
          t("hse_student"),
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
