import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { useTranslation } from "react-i18next";

function AboutCard() {
  const { i18n, t } = useTranslation();
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t("hello_everyone")} {t("i_am_caps")[0]+t("i_am_caps").toLowerCase().slice(1)} <span className="purple">{t("my_name")} </span>
            {t("from")} <span className="purple"> {t("moscow_russia")}.</span>
            <br />
            {t("iamcurrempl")}
            <br />
            {t("myeduabout")}
            <br />
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
