import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";


i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    projects: "Projects",
                    about: "About",
                    home: "Home",
                    resume: "Resume",
                    feedback_menu: "Feedback",
                    switch_lang: "Switch to Russian",
                    create_order: "Create order",
                    hello_everyone: "Hello everyone!",
                    my_name_caps: "ARTEM KLUSHIN",
                    my_name: "Artem Klushin",
                    i_am_caps: "I'M",
                    freelancer: "Freelancer",
                    winner_of_prod: "Winner of PROD contest",
                    hse_student: "HSE student",
                    let_me: "LET ME",
                    introduce: "INTRODUCE",
                    myself: "MYSELF",
                    istartedprogr: "I started programming when I was very young. For me, it is not just a job, it is the ",
                    meaningofmylife: "meaning of my life",
                    and: "and",
                    ihaveexcellknowlang: "I have excellent knowledge of languages like",
                    icanhome2: "I can automate your business by creating virtual employees, bots. I can build telegram bots, parsers, websites and more.",
                    mystackis: "My stack is",
                    etc: ", etc.",
                    why: 'WHY',
                    mecaps: 'ME',
                    makequickhome2: "I'll make your project quick.",
                    opentocommunic: "I'm open to communication.",
                    readylearn: "I'm always ready to learn new things.",
                    findmeoncaps: "FIND ME ON",
                    feelfreeto: "Feel free to",
                    connect_feelfree: "connect",
                    withme_feelfree: "with me",
                    knowwho: "Know Who",
                    from: "from",
                    moscow_russia: "Moscow, Russia",
                    iamcurrempl: "I am currently employed as a freelancer at Kwork.",
                    myeduabout: "I have completed a lot of online courses. I am also studing at FCS of HSE."
                },
            },
            ru: {
                translation: {
                    projects: "Проекты",
                    about: "Обо мне",
                    home: "Главная",
                    resume: "Резюме",
                    feedback_menu: "Отзывы",
                    switch_lang: "На английском",
                    create_order: "Сделать заказ",
                    hello_everyone: "Всем привет!",
                    my_name_caps: "АРТЁМ КЛУШИН",
                    my_name: "Артём Клушин",
                    i_am_caps: "Я",
                    freelancer: "Фрилансер",
                    winner_of_prod: "Победитель олимпиады PROD",
                    hse_student: "Студент ФКН НИУ ВШЭ",
                    let_me: "ДЛЯ НАЧАЛА",
                    introduce: "ПРЕДСТАВЛЮСЬ",
                    myself: "",
                    istartedprogr: "Я начал программировать ещё в детстве. Для меня это не просто работа, это - ",
                    meaningofmylife: "моё предназначение",
                    and: 'и',
                    ihaveexcellknowlang: "Я очень хорошо владею языками программирования, в особеннности ",
                    icanhome2: "Я могу автоматизировать Ваш бизнес, создавая виртуальных рабочих и ботов. Я могу создать телеграм боты, сайты, парсеры и много другое.",
                    mystackis: "Мой стэк:",
                    etc: " и др.",
                    why: 'ПОЧЕМУ',
                    mecaps: 'Я',
                    makequickhome2: "Я сделаю Ваш проект быстро.",
                    opentocommunic: "Я всегда открыт к общению.",
                    readylearn: "Я всегда готов изучать что-то новое.",
                    findmeoncaps: "СВЯЗАТЬСЯ СО МНОЙ",
                    feelfreeto: "Не стесняйтесь",
                    connect_feelfree: "обращаться",
                    withme_feelfree: "ко мне",
                    knowwho: "Кто",
                    from: "из",
                    moscow_russia: "Москвы",
                    iamcurrempl: "Сейчас я работаю фрилансером на Kwork.",
                    myeduabout: "Я окончил множество онлайн-курсов. Также сейчас я получая образование на ФКН НИУ ВШЭ."
                },
            },
        },
    });

export default i18n;