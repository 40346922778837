import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillMessage
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Home2() {
  const { i18n, t } = useTranslation();
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {t("let_me")} <span className="purple"> {t("introduce")} </span> {t("myself")}
            </h1>
            <p className="home-about-body">
               {t("istartedprogr")}<span className="purple"> {t("meaningofmylife")}</span> 
              <br />
              <br />{t("ihaveexcellknowlang")} 
              <b className="purple"> Python {t('and')} Javascript. </b>
              <br />
              <br />
              {t("icanhome2")}
              <br />
              <br />
              {t("mystackis")} <span className="purple">react.js, django, aiogram3, telethon, pyrogram, flutter</span>{t('etc')}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
                {t('why')} <span className="purple"> {t('mecaps')}</span>? 
            </h1>
            <p className="home-about-body">
              <ul>
                <li>{t("makequickhome2")}</li>
                <li>{t("opentocommunic")}</li>
                <li>{t("readylearn")}</li>
              </ul>
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t("findmeoncaps")}</h1>
            <p>
              {t("feelfreeto")} <span className="purple">{t("connect_feelfree")} </span>{t("withme_feelfree")}
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/arklual"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              {/*<li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>*/}
              <li className="social-icons">
                <a
                  href="https://t.me/arklual"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillMessage />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
